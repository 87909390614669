import React, { useEffect } from 'react';

import Layout from '../components/layout'
import SeoOne from '../components/seo/SeoOne'
import SeoTwo from '../components/seo/SeoTwo'
import SeoThree from '../components/seo/SeoThree'
import SeoFour from '../components/seo/SeoFour'
import SeoFive from '../components/seo/SeoFive'
import SeoIntro from '../components/seo/SeoIntro'
import AuditCta from '../components/audit/AuditCta'

const Seo = props => {
    useEffect(() => {
        window.scrollTo(0, 0);
        
        // Find all elements with the specified class
        const ctaElements = document.querySelectorAll('.audit-anchor-link'); // Replace with your actual class name
    
        // Add click event listener to each element
        ctaElements.forEach(element => {
          element.addEventListener('click', handleCTAClick);
        });
    
        // Define the click event handler
        function handleCTAClick(event) {
          event.preventDefault();
    
          const meetingsContainer = document.querySelector('.meetings-iframe-container'); // Get the meetings container
          if (meetingsContainer) {
            meetingsContainer.classList.add('visible'); // Show the meetings container
    
            // Scroll to the meetings container after a short delay
            setTimeout(() => {
              meetingsContainer.scrollIntoView({ behavior: 'smooth' });
            }, 100);
    
            // Add the script to create the iframe
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = 'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js';
            script.async = true;
            meetingsContainer.appendChild(script);
          }
        }
    
        // Cleanup: Remove event listeners when the component unmounts
        return () => {
          ctaElements.forEach(element => {
            element.removeEventListener('click', handleCTAClick);
          });
        };
      }, []);

      return (
    <Layout
      lang='pt'
      title="Aumente a sua visibilidade online WordPress!"
      meta={{
        description: 'Quer aparecer no topo dos motores de busca? Otimize a sua presença online com a auditoria de SEO e Indexação da log.',
      }}
      rootProps={{ className: 'page work audit-page bg-blue' }}
      headerProps={{ isBlue: true }}
    >
      <SeoOne lang="pt" />
      <SeoIntro lang="pt" />
      <SeoTwo lang="pt" />
      <SeoFour lang="pt" />
      <SeoThree lang="pt" />
      <AuditCta lang="pt" />
      <SeoFive lang="pt" />
    </Layout>
  );
};

export default Seo
