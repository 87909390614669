import React from 'react'
import _ from 'lodash'

import introimg from '../../assets/images/audit/intro/seo-intro.png'

import TextGroup, { Image } from '../Texts'

const SeoIntro = ({ lang }) => {
  const isEn = lang === 'en'

  return (
    <section id="performance-intro" className="bg-white bg-gray lp-intro">
      <div className="content container">
        <div className="row">
            <div className="tile row">
                <div className="col-6 col-12-medium image image-margin-right">
                <Image image={introimg} loading="lazy" />
                </div>
                <div className="text-group col-6 col-12-medium">
                    <h2>Análise SEO</h2>
                    <p>No vasto mundo digital, a visibilidade é a chave para o sucesso. E é aqui que entra o Search Engine Optimization (SEO). O SEO não é apenas sobre ser encontrado; é sobre ser escolhido. É sobre destacar-se entre a concorrência, atrair tráfego qualificado e conquistar a confiança dos utilizadores.</p>
                    <ul>
                        <li>Desbloqueamos o verdadeiro potencial do seu website WordPress</li>
                        <li>Disponibilizamos serviços especializados em SEO</li>
                        <li>Adotamos uma abordagem alinhada, para resultados duradouros.</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    </section>
  )
}
export default SeoIntro
