import React, { useEffect, useState } from 'react';
import TextGroup, { CTA } from '../Texts'

import audit2 from '../../assets/images/audit/audit-2.png'
import ServiceModal from "../ServiceModal";
import close_icon from '../../assets/images/close.svg'

const SeoTwo = ({ lang }) => {
  const isEn = lang === 'en';
  const [clickedTileIndexes, setClickedTileIndexes] = useState([]);

  const [modalData, setModalData] = useState();
  const [modalVisible, setModalVisible] = useState(false);

  const handleTileClick = (index) => {

    setClickedTileIndexes((prevIndexes) => {
      if (prevIndexes.includes(index)) {
        return prevIndexes.filter((id) => id !== index);
      } else {
        return [...prevIndexes, index];
      }
    });

    //show modal
    setModalVisible(true);
    // set modal data on click
    setModalData(sliderTiles[index]);
  };
    

   // hide modal on close.
   const onModalCloseHandler = () => {
    setModalVisible(false);
    setClickedTileIndexes([]);
  };
    

  const sliderTiles = [
    {
      rootProps: { className: 'tile' },
      title: {
        type: 'h3',
        text: isEn ? 'Análise de Palavras-chave' : 'Análise de Palavras-chave',
      },
      modal: {
        title:"Análise de Palavras-chave",
        description: "Identificamos as palavras-chave ideais para atrair tráfego relevante e potenciar a visibilidade online do seu negócio.",
      }
    },
    {
      rootProps: { className: 'tile' },
      title: {
        type: 'h3',
        text: isEn ? 'Arquitetura do Site' : 'Arquitetura do Site',
      },
      modal: {
        title:"Arquitetura do Site",
        description: "Melhoramos a estrutura do seu website, facilitando a experiência do usuário e a indexação pelos motores de busca.",
      }
    },
    {
      rootProps: { className: 'tile' },
      title: {
        type: 'h3',
        text: isEn ? 'Conteúdo' : 'Conteúdo',
      },
      modal: {
        title:"Conteúdo",
        description: "Produzimos conteúdos envolventes para elevar a autoridade, garantindo uma posição destacada nos resultados de pesquisa.",
      }
    },
    {
      rootProps: { className: 'tile' },
      title: {
        type: 'h3',
        text: isEn ? 'Compatibilidade com Dispositivos Móveis' : 'Compatibilidade com Dispositivos Móveis',
      },
      modal: {
        title:"Compatibilidade com Dispositivos Móveis",
        description: "Adaptamos o website para dispositivos móveis, assegurando acessibilidade e elevando a classificação nos rankings de pesquisa móvel.",
      }
    },
    {
        rootProps: { className: 'tile' },
        title: {
          type: 'h3',
          text: isEn ? 'SEO Técnico' : 'SEO Técnico',
        },
        modal: {
          title:"SEO Técnico",
          description: "Aprimoramos tecnicamente o seu website, da velocidade de carregamento à estrutura de URL, para melhorar a visibilidade.",
        }
       
      },
      {
        rootProps: { className: 'tile' },
        title: {
          type: 'h3',
          text: isEn ? 'Segurança' : 'Segurança',
        },
        modal: {
          title:"Segurança",
          description: "Fortalecemos a confiança dos visitantes e dos motores de busca, impactando positivamente no ranking e na reputação online.",
        }
      },
      {
        rootProps: { className: 'tile' },
        title: {
          type: 'h3',
          text: isEn ? 'Imagens e Multimédia' : 'Imagens e Multimédia',
        },
        modal: {
          title:"Imagens e Multimédia",
          description: "Aperfeiçoamos a vertente de media para melhorar a experiência do usuário, reduzindo o tempo de carregamento e atraindo mais tráfego.",
        }
      },
      {
        rootProps: { className: 'tile' },
        title: {
          type: 'h3',
          text: isEn ? 'Backlinks' : 'Backlinks',
        },
       
        modal: {
          title:"Backlinks",
          description: "Criamos ligações sólidas para aumentar a credibilidade, influenciando positivamente a classificação nos resultados de pesquisa.",
        }
      },
      {
        rootProps: { className: 'tile' },
        title: {
          type: 'h3',
          text: isEn ? 'Correções e Ações' : 'Correções e Ações',
        },
       
        modal: {
          title:"Correções e Ações",
          description: "Mantemos o site sem erros, agindo rapidamente diante de problemas, para impactar diretamente no desempenho e na confiança dos motores de busca.",
        }
      },
  ]

  return (
    <section id="services-two" className="bg-white services lp-tiles audit-two">
      <div className="content container">
        <div className="col-12 slider-wrapper">
          <TextGroup
            rootProps={{
              className: 'col-12 heading-large',
            }}
            title={{
              type: 'h2',
              text: isEn
                ? 'We work with you as one team.'
                : 'Serviços log SEO:',
            }}
          />
          <div className="audit2-cnt">
            <div className="row tiles-row row-relative">
              {sliderTiles.map((tile, i) => (
                <div
                  key={`services-column-${i}`}
                  className={`col-4 col-12-medium tile-column ${
                    clickedTileIndexes.includes(i) ? 'clicked' : ''
                  }`}
                  onClick={() => handleTileClick(i)}
                >
                  <TextGroup {...tile} />
                </div>
              ))}
            </div>
            <div className="cta cta-audit">
              <CTA
                rootProps={{
                  className: 'button primary audit-anchor-link gethelp-two',
                  to: isEn ? '#' : '#',
                }}
                text={isEn ? "EN" : 'Obtenha a nossa ajuda'}
              />
            </div>
          </div>
        </div>
      </div>

      <ServiceModal
        visible={modalVisible}
        title={modalData?.modal?.title}
        description={modalData?.modal?.description}
      >
        <img 
          src={close_icon} 
          alt="Close" 
          className="service-modal__close"
          onClick={onModalCloseHandler}
        />
          <a 
            href="#" 
            className="button audit-btn audit-anchor-link gethelp-box"
            onClick={onModalCloseHandler}
          >
           {isEn ? "Schedule a Free Consultation" : 'Obtenha a nossa ajuda'}
          </a>
        </ServiceModal>
    </section>
  )
}

export default SeoTwo
