import React from 'react'
import _ from 'lodash'

import TextGroup, { Image } from '../Texts'

import logo1 from '../../assets/images/audit/services/seguranca.png'
import logo2 from '../../assets/images/audit/services/suporte.png'
import logo3 from '../../assets/images/audit/services/performance.png'

const main_services = [
    {
        image: logo1,
        text: 'Elevamos a segurança a um novo patamar. Proteja o seu site WordPress e os dados dos seus clientes contra ciberataques.',
        title: 'Segurança',
        link: '/proteja-o-seu-site-wordpress-e-o-seu-negocio',
    },
    {
        image: logo3,
        text: 'A velocidade importa. Otimize a desempenho do seu site WordPress, para uma experiência de utilizador eficaz.',
        title: 'Performance',
        link: '/torne-o-seu-site-wordpress-mais-rapido',
    },
    {
        image: logo2,
        text: 'Não enfrente desafios sozinho. Recupere a credibilidade do seu site WordPress com a ajuda da log, onde as suas questões e problemas têm solução.',
        title: 'Suporte',
        link: '/o-seu-site-wordpress-esta-lento-e-com-bugs',
    },
]



const SeoFive = ({ lang }) => {
  const isEn = lang === 'en';
  return (
    <section id="audit-five" className="bg-white bg-gray audit-five">
        <div className="container content main-services">
            <TextGroup
            title={{
                type: 'h2',
                text: isEn ? 'Brands that trust us:' : 'Outros Serviços',
            }}
            />
            <p className="p-sub">A nossa missão é potenciar a presença online do seu website WordPress, a otimização de SEO é apenas o ponto de partida. 
Descubra os nossos serviços dedicados a otimizar o seu website WordPress e WooCommerce. Agende uma reunião com um dos nossos consultores.</p>
            <div className="row">
                {_.map(main_services, (service, i) => (
                    <div className="col-4 col-12-medium">
                        <React.Fragment>
                            <a href={service.link}>
                                <div className="other-service">
                                    <Image {...service} loading="lazy" />
                                    <p>{service.title}</p>
                                    <span>{service.text}</span>
                                </div>
                            </a>
                        </React.Fragment>
                    </div>
                ))}
            </div>
        </div>
    </section>
  )
}
export default SeoFive
